body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("/assets/background.jpg");
  background-position: top;
  font-size: 1.05rem !important;
  color: #000000
}
.navabar {
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-overlay {
    background-color: rgb(191, 191, 191, 0.8);
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

@media (min-width: 768px) {
  .my-form .form-label {
    display: inline-block;
    width: 150px;
  } 
  .my-form .form-control,.mobile-block {
    display: inline-block;
    width: calc(100% - 150px);
  }
  .min-card {
    min-height: 110px;
  }
  .area {
    vertical-align: bottom;
  }
  .my-form .bl{
    width: 70%;
  }
  .my-form .si{
    width:30%;
  }
  .mobile-block .prefix {
    width:140px;
  }
}
@media (max-width: 480px) {
  .patient-card .btn{
    float: right !important;
    display: block;
    margin-top: 10px;
  }
}
.details label{
width: 130px;
font-weight: bold;
}
.detail-modal .modal-dialog{
  max-width: 1100px
}
.inline-msg {
  display: none;
}

.success .inline-msg {
  display: block;
  color: green;
}

.error .inline-msg {
  display: block;
  color: red;
}

.req .form-label:after, .req .form-check-label:before, .req-label:after { 
  content:" *";
  color:red;
}
.nh {
  font-size: 24px;
  font-weight: bolder;
}
.tbl {
  background:rgb(255,255,255, 0.5);
}

.address p {
  margin-bottom: 5px;
}
  ul.pagination {
    display: inline-block
  }
.pagination > li {
  display: inline-block;
  padding-left: 0;
}
.pagination > li {
  list-style: none;
  border: 0.9px solid;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #2c689c;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}

.pagination>li.active>a {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

/* Style the active class (and buttons on mouse-over) */
.pagination > li > a:hover {
  background-color:  #218838;
  color: white;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
    padding: 0px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  display: none!important;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  margin-right: 0;
  padding: 0px!important;
  border-top-right-radius: 4px;
  display: none!important;
}
.mobile-block .prefix {
  margin-bottom: 10px;
  margin-right: 10px;
}
.form-check .form-check-label {
  max-width: 100%
}
.footer{
  margin-top: 20px;
  padding: 30px 0px;
  background: #49494b;
  color: #fff;
}

.footer div{
  min-height: 50px;
}

.footer div a{
 margin-right: 10px;
}
.page-content {
  min-height: 80vh;
}