
:root {
    --body-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    --msger-bg: #fff;
    --border: 2px solid #ddd;
    --left-msg-bg: #ececec;
    --right-msg-bg: #579ffb;
  }
.msger {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    width: 100%;
    /* max-width: 867px;
    margin: 25px 10px; */
    height: calc(100% - 50px);
    border: var(--border);
    border-radius: 5px;
    background: var(--msger-bg);
    box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
  }
  
  .msger-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: var(--border);
    background: #eee;
    color: #666;
  }

  .msger-header-title {
    font-weight: bold;
    color: black;
  }
  
  .msger-chat {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
  }
  .msger-chat::-webkit-scrollbar {
    width: 6px;
  }
  .msger-chat::-webkit-scrollbar-track {
    background: #ddd;
  }
  .msger-chat::-webkit-scrollbar-thumb {
    background: #bdbdbd;
  }
  .msg {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
  }
  .msg:last-of-type {
    margin: 0;
  }
  .msg-img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    background: #ddd;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
  }
  .msg-bubble {
    min-width: 300px;
    width: 49%;
    padding: 15px;
    border-radius: 15px;
    background: var(--left-msg-bg);
  }
  .msg-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .msg-info-name {
    margin-right: 10px;
    font-weight: bold;
  }
  .msg-info-time {
    font-size: 0.85em;
  }
  
  .left-msg .msg-bubble {
    border-bottom-left-radius: 0;
  }
  
  .right-msg {
    flex-direction: row-reverse;
  }
  .right-msg .msg-bubble {
    background: var(--right-msg-bg);
    color: #fff;
    border-bottom-right-radius: 0;
  }
  .right-msg .msg-img {
    margin: 0 0 0 10px;
  }
  
  .msger-inputarea {
    padding: 10px;
    border-top: var(--border);
    background: #eee;
  }
  .msger-inputarea * {
    padding: 10px;
    border: none;
    border-radius: 3px;
    font-size: 1em;
  }

  .msger-inputarea textarea {
    width: 100%;
  }
  .msger-input {
    flex: 1;
    background: #ddd;
  }
  .msger-send-btn, .msger-upload-btn {
    margin-left: 10px;
    background: rgb(0, 196, 65);
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.23s;
    height:45px
  }
  .msger-upload-btn {
    background-color: #579ffb;
    min-width: calc(100% - 90px)
  }
  .msger-send-btn:hover {
    background: rgb(0, 180, 50);
  }
  
  .msger-chat {
    background-color: #fcfcfe;
    max-height: 450px;
    min-height: 100px;
  }
  .msg-img img {
    margin:10px;
  }
  .msg-text {
    font-family: "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 16.8px;
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;
  }
  .left-msg .msg-text {
    color: rgb(33, 37, 41);
  }

  .right-msg .msg-text {
    color: rgb(255, 255, 255);
  }

 .custom-control {
    width: 0px;
    position: absolute;
    margin-left: 5px;
  }

  .form-check {
    padding: 0px !important;
  }
  .form-check label {
    margin-left: 10px;
    max-width: 110px;
  }

  .msg-bubble.internal {
    background-color: orange;
  }

  @media (min-width: 768px) {
    .msg-bubble {
      min-width: 320px;
      width: 49%;
    }
  }

  @media (max-width: 768px) {
    .msg-bubble {
      min-width: 80%;
      width: 85%;
    }
    .stack button, .stack .form-check {
      display: inline-block;
    }
  }
  .fileAct {
    display: flex
  }